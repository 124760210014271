import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import { Router, RouterModule, Routes } from '@angular/router';
import { RootNavigationComponent } from './features/dashboard/navigation/root-nav/root-nav.component';
import { PatientEditPersonalComponent } from './features/dashboard/patients/patient-edit/patient-edit-personal/patient-edit-personal.component';
import { PatientEditComponent } from './features/dashboard/patients/patient-edit/patient-edit.component';
import { PatientRegisterComponent } from './features/dashboard/patients/patient-register/patient-register.component';
import { PatientsListComponent } from './features/dashboard/patients/patients-list/patients-list.component';
import { LoginComponent } from './features/public/login/login.component';
import { FormExitGuard } from './core/guards/form-exit.guard';
import { PatientEditImageDatabaseComponent } from './features/dashboard/patients/patient-edit/patient-edit-image-database/patient-edit-image-database.component';
import { PatientEditAttachmentsComponent } from './features/dashboard/patients/patient-edit/patient-edit-attachments/patient-edit-attachments.component';
import { RegistersComponent } from './features/dashboard/registers/registers.component';
import { ClinicsListComponent } from './features/dashboard/registers/clinics/clinics-list/clinics-list.component';
import { ClinicFormComponent } from './features/dashboard/registers/clinics/clinic-form/clinic-form.component';
import { ProfessionalsListComponent } from './features/dashboard/registers/professionals/professionals-list/professionals-list.component';
import { PatientTagsListComponent } from './features/dashboard/registers/patient-tags/patient-tags-list/patient-tags-list.component';
import { PatientTagsFormComponent } from './features/dashboard/registers/patient-tags/patient-tags-form/patient-tags-form.component';
import { ProfessionsListComponent } from './features/dashboard/registers/professions/professions-list/professions-list.component';
import { ProfessionsFormComponent } from './features/dashboard/registers/professions/professions-form/professions-form.component';
import { SchedulesComponent } from './features/dashboard/schedules/schedules.component';
import { SystemUsersListComponent } from './features/dashboard/registers/system-users/system-users-list/system-users-list.component';
import { SystemUsersFormComponent } from './features/dashboard/registers/system-users/system-users-form/system-users-form.component';
import { UserGroupsListComponent } from './features/dashboard/registers/user-groups/user-groups-list/user-groups-list.component';
import { UserGroupRegisterComponent } from './features/dashboard/registers/user-groups/user-group-register/user-group-register.component';
import { SchedulesListComponent } from './features/dashboard/registers/schedules/schedules-list/schedules-list.component';
import { HealthInsurancesListComponent } from './features/dashboard/registers/health-insurances/health-insurances-list/health-insurances-list.component';
import { HealthInsurancesFormComponent } from './features/dashboard/registers/health-insurances/health-insurances-form/health-insurances-form.component';
import { PublicRouteGuard } from './core/guards/public-route.guard';
import { ProtectedRouteGuard } from './core/guards/protected-route.guard';
import { UserGroupEditComponent } from './features/dashboard/registers/user-groups/user-group-edit/user-group-edit.component';
import { ClinicTagsListComponent } from './features/dashboard/registers/clinic-tags/clinic-tags-list/clinic-tags-list.component';
import { ClinicTagsFormComponent } from './features/dashboard/registers/clinic-tags/clinic-tags-form/clinic-tags-form.component';
import { ProfessionalTagsListComponent } from './features/dashboard/registers/professional-tags/professional-tags-list/professional-tags-list.component';
import { ProfessionalTagsFormComponent } from './features/dashboard/registers/professional-tags/professional-tags-form/professional-tags-form.component';
import { SpecialtiesListComponent } from './features/dashboard/registers/specialties/specialties-list/specialties-list.component';
import { SpecialtiesFormComponent } from './features/dashboard/registers/specialties/specialties-form/specialties-form.component';
import { PasswordRecoveryComponent } from './features/public/password-recovery/password-recovery.component';
import { PasswordSetComponent } from './features/public/password-set/password-set.component';
import { ScheduleNotLoggedInComponent } from './features/public/schedule-not-logged-in/schedule-not-logged-in.component';
import { RedefinePasswordComponent } from './features/dashboard/registers/system-users/redefine-password/redefine-password.component';
import { ProceduresListComponent } from './features/dashboard/registers/procedures/procedures-list/procedures-list.component';
import { ProceduresFormComponent } from './features/dashboard/registers/procedures/procedures-form/procedures-form.component';
import { DocumentsListComponent } from './features/dashboard/registers/documents/documents-list/documents-list.component';
import { DocumentsFormComponent } from './features/dashboard/registers/documents/documents-form/documents-form.component';
import { SchedulingFormComponent } from './features/dashboard/schedules/scheduling-form/scheduling-form.component';
import { SchedulingStatusListComponent } from './features/dashboard/registers/scheduling-status/scheduling-status-list/scheduling-status-list.component';
import { SchedulingStatusFormComponent } from './features/dashboard/registers/scheduling-status/scheduling-status-form/scheduling-status-form.component';
import { AttendanceTypeListComponent } from './features/dashboard/registers/attendance-type/attendance-type-list/attendance-type-list.component';
import { AttendanceTypeFormComponent } from './features/dashboard/registers/attendance-type/attendance-type-form/attendance-type-form.component';
import { ProfessionalsFormComponent } from './features/dashboard/registers/professionals/professionals-form/professionals-form.component';
import { SchedulesFormComponent } from './features/dashboard/registers/schedules/schedules-form/schedules-form.component';
import { PatientEditDocumentsComponent } from './features/dashboard/patients/patient-edit/patient-edit-documents/patient-edit-documents.component';
import { TriggersListComponent } from './features/dashboard/registers/triggers/triggers-list/triggers-list.component';
import { TriggersFormComponent } from './features/dashboard/registers/triggers/triggers-form/triggers-form.component';
import { PatientEditSchedulingsListComponent } from './features/dashboard/patients/patient-edit/patient-edit-schedulings-list/patient-edit-schedulings-list.component';
import { WelcomeMessageFormComponent } from './features/dashboard/registers/welcome-message/welcome-message-form/welcome-message-form.component';
import { AttendanceListComponent } from './features/dashboard/charts/attendance-list/attendance-list.component';
import { MedicalRecordsComponent } from './features/dashboard/charts/medical-records/medical-records.component';
import { ExamsTemplateListComponent } from './features/dashboard/registers/exams-template/exams-template-list/exams-template-list.component';
import { ExamsTemplateFormComponent } from './features/dashboard/registers/exams-template/exams-template-form/exams-template-form.component';
import { PatientEditExamComponent } from './features/dashboard/patients/patient-edit/patient-edit-exam/patient-edit-exam.component';
import { ManualTriggerFormComponent } from './features/dashboard/registers/manual-trigger/manual-trigger-form/manual-trigger-form.component';
import { StatusReminderListComponent } from './features/dashboard/registers/status-reminder/status-reminder-list/status-reminder-list.component';
import { StatusReminderFormComponent } from './features/dashboard/registers/status-reminder/status-reminder-form/status-reminder-form.component';
import { EndConversationFormComponent } from './features/dashboard/registers/end-conversation/end-conversation-form/end-conversation-form.component';
import { FinancialTableComponent } from './features/dashboard/financial/financial-table/financial-table.component';
import { FinancialStatusListComponent } from './features/dashboard/registers/financial-status/financial-status-list/financial-status-list.component';
import { FinancialStatusFormComponent } from './features/dashboard/registers/financial-status/financial-status-form/financial-status-form.component';
import { FinancialFormComponent } from './features/dashboard/financial/financial-form/financial-form.component';
import { StagesStatusListComponent } from './features/dashboard/registers/stages-status/stages-status-list/stages-status-list.component';
import { StagesStatusFormComponent } from './features/dashboard/registers/stages-status/stages-status-form/stages-status-form.component';
import { OximeterComponent } from './features/dashboard/oximeter/oximeter.component';
import { ExamsListComponent } from './features/dashboard/oximeter/exams/exams-list/exams-list.component';
import { OximetersListComponent } from './features/dashboard/oximeter/oximeters/oximeters-list/oximeters-list.component';
import { OximetersFormComponent } from './features/dashboard/oximeter/oximeters/oximeters-form/oximeters-form.component';
import { ExamItemComponent } from './features/dashboard/oximeter/exams/exam-item/exam-item.component';
import { HistoricComponent } from './features/dashboard/oximeter/oximeters/historic/historic.component';
import { PatientEditPolysomnographyListComponent } from './features/dashboard/patients/patient-edit/patient-edit-polysomnography-list/patient-edit-polysomnography-list.component';
import {
  FinancialTasksTableComponent
} from "./features/dashboard/registers/financial-tasks/financial-tasks-table/financial-tasks-table.component";
import {
  FinancialTasksFormComponent
} from "./features/dashboard/registers/financial-tasks/financial-tasks-form/financial-tasks-form.component";
import {LeadsComponent} from "./features/dashboard/leads/leads.component";
import {
  ExamsRegisterListComponent
} from "./features/dashboard/registers/exams-register/exams-register-list/exams-register-list.component";
import {
  ExamsRegisterFormComponent
} from "./features/dashboard/registers/exams-register/exams-register-form/exams-register-form.component";
import {
  ExamsRequestListComponent
} from "./features/dashboard/exams-request/exams-request-list/exams-request-list.component";
import {
  ExamsRequestFormComponent
} from "./features/dashboard/exams-request/exams-request-form/exams-request-form.component";

const routes: Routes = [
  { path: "", pathMatch: "prefix", redirectTo: "login" },
  { path: 'login', component: LoginComponent, canActivate: [PublicRouteGuard] },
  { path: 'passwordRecovery', component: PasswordRecoveryComponent, canActivate: [PublicRouteGuard] },
  { path: 'set_password/:code', component: PasswordSetComponent, canActivate: [PublicRouteGuard] },
  { path: 'schedule/:document', component: ScheduleNotLoggedInComponent, canActivate: [PublicRouteGuard] },
  {
    path: 'dashboard', component: RootNavigationComponent, canActivate: [ProtectedRouteGuard], children: [
      { path: "", pathMatch: "prefix", redirectTo: "schedules" },
      { path: 'leads', component: LeadsComponent },
      {
        path: "oximeter", children: [
          {
            path: "", component: OximeterComponent, children: [
              { path: "", pathMatch: "prefix", redirectTo: "exams" },
              {
                path: "exams", children: [
                  { path: "", component: ExamsListComponent },
                  { path: "edit/:id", component: ExamItemComponent },
                ]
              },
              {
                path: "oximeters", children: [
                  { path: "", component: OximetersListComponent },
                  { path: "edit/:id", component: OximetersFormComponent },
                  { path: "register", component: OximetersFormComponent, canDeactivate: [FormExitGuard] },
                  { path: "historic/:id", component: HistoricComponent },
                ]
              }
            ]
          },
        ]
      },
      {
        path: 'schedules', children: [
          { path: "", component: SchedulesComponent },
          { path: "new-event", component: SchedulingFormComponent },
          { path: ":idAgenda/edit-event/:idAtendimento", component: SchedulingFormComponent }
        ]
      },

      {
        path: "charts", children: [
          { path: "", component: AttendanceListComponent },
          { path: "register", component: MedicalRecordsComponent, canDeactivate: [FormExitGuard] },
          { path: "edit/:id", component: MedicalRecordsComponent, canDeactivate: [FormExitGuard] }
        ]
      },

      {
        path: "exams-request", children: [
          { path: "", component: ExamsRequestListComponent },
          { path: "register", component: ExamsRequestFormComponent, canDeactivate: [FormExitGuard] },
          { path: "edit/:id", component: ExamsRequestFormComponent, canDeactivate: [FormExitGuard] }
        ]
      },

      {
        path: "patients", children: [
          { path: "", component: PatientsListComponent },
          { path: "register", component: PatientRegisterComponent, canDeactivate: [FormExitGuard] },
          {
            path: "edit/:id", component: PatientEditComponent, children: [
              { path: "", pathMatch: "prefix", redirectTo: "personal" },
              { path: "personal", component: PatientEditPersonalComponent },
              { path: "imageDatabase", component: PatientEditImageDatabaseComponent },
              { path: "attachments", component: PatientEditAttachmentsComponent },
              { path: "patientDocuments", component: PatientEditDocumentsComponent },
              { path: "patientSchedulings", component: PatientEditSchedulingsListComponent },
              { path: "patientExams", component: PatientEditExamComponent },
              { path: "patientPolysomnography", component: PatientEditPolysomnographyListComponent }
            ]
          }
        ]
      },

      {
        path: "financial", children: [
          { path: "", component: FinancialTableComponent },
          { path: "register", component: FinancialFormComponent, canDeactivate: [FormExitGuard] },
          { path: "edit/:id", component: FinancialFormComponent, canDeactivate: [FormExitGuard] }
        ]
      },

      {
        path: "registers", children: [
          {
            path: "", component: RegistersComponent, children: [
              { path: "", pathMatch: "prefix", redirectTo: "clinics" },
              {
                path: "clinics", children: [
                  { path: "", component: ClinicsListComponent },
                  { path: "register", component: ClinicFormComponent, canDeactivate: [FormExitGuard] },
                  { path: "edit/:id", component: ClinicFormComponent, canDeactivate: [FormExitGuard] },
                ]
              },
              {
                path: "professionals", children: [
                  { path: "", component: ProfessionalsListComponent },
                  { path: "register", component: ProfessionalsFormComponent, canDeactivate: [FormExitGuard] },
                  { path: "edit/:id", component: ProfessionalsFormComponent, canDeactivate: [FormExitGuard] },
                ]
              },
              {
                path: "systemUsers", children: [
                  { path: "", component: SystemUsersListComponent },
                  { path: "register", component: SystemUsersFormComponent, canDeactivate: [FormExitGuard] },
                  { path: "edit/:id", component: SystemUsersFormComponent, canDeactivate: [FormExitGuard] },
                  { path: "redefine_password/:id", component: RedefinePasswordComponent, canDeactivate: [FormExitGuard] },
                ]
              },
              {
                path: "userGroups", children: [
                  { path: "", component: UserGroupsListComponent },
                  { path: "register", component: UserGroupRegisterComponent, canDeactivate: [FormExitGuard] },
                  { path: "edit/:id", component: UserGroupEditComponent, canDeactivate: [FormExitGuard] },
                ]
              },
              {
                path: "schedules", children: [
                  { path: "", component: SchedulesListComponent },
                  { path: "register", component: SchedulesFormComponent, canDeactivate: [FormExitGuard] },
                  { path: "edit/:id", component: SchedulesFormComponent, canDeactivate: [FormExitGuard] },
                ]
              },
              {
                path: "healthInsurances", children: [
                  { path: "", component: HealthInsurancesListComponent },
                  { path: "register", component: HealthInsurancesFormComponent, canDeactivate: [FormExitGuard] },
                  { path: "edit/:id", component: HealthInsurancesFormComponent, canDeactivate: [FormExitGuard] },
                ]
              },
              {
                path: "professions", children: [
                  { path: "", component: ProfessionsListComponent },
                  { path: "register", component: ProfessionsFormComponent, canDeactivate: [FormExitGuard] },
                  { path: "edit/:id", component: ProfessionsFormComponent, canDeactivate: [FormExitGuard] },
                ]
              },
              {
                path: "patientTags", children: [
                  { path: "", component: PatientTagsListComponent },
                  { path: "register", component: PatientTagsFormComponent, canDeactivate: [FormExitGuard] },
                  { path: "edit/:id", component: PatientTagsFormComponent, canDeactivate: [FormExitGuard] },
                ]
              },
              {
                path: "clinicTags", children: [
                  { path: "", component: ClinicTagsListComponent },
                  { path: "register", component: ClinicTagsFormComponent, canDeactivate: [FormExitGuard] },
                  { path: "edit/:id", component: ClinicTagsFormComponent, canDeactivate: [FormExitGuard] },
                ]
              },
              {
                path: "professionalTags", children: [
                  { path: "", component: ProfessionalTagsListComponent },
                  { path: "register", component: ProfessionalTagsFormComponent, canDeactivate: [FormExitGuard] },
                  { path: "edit/:id", component: ProfessionalTagsFormComponent, canDeactivate: [FormExitGuard] },
                ]
              },
              {
                path: "specialties", children: [
                  { path: "", component: SpecialtiesListComponent },
                  { path: "register", component: SpecialtiesFormComponent, canDeactivate: [FormExitGuard] },
                  { path: "edit/:id", component: SpecialtiesFormComponent, canDeactivate: [FormExitGuard] },
                ]
              },
              {
                path: "procedures", children: [
                  { path: "", component: ProceduresListComponent },
                  { path: "register", component: ProceduresFormComponent, canDeactivate: [FormExitGuard] },
                  { path: "edit/:id", component: ProceduresFormComponent, canDeactivate: [FormExitGuard] },
                ]
              },
              {
                path: "financialStatus", children: [
                  { path: "", component: FinancialStatusListComponent },
                  { path: "register", component: FinancialStatusFormComponent, canDeactivate: [FormExitGuard] },
                  { path: "edit/:id", component: FinancialStatusFormComponent, canDeactivate: [FormExitGuard] }
                ]
              },
              {
                path: "stageStatus", children: [
                  { path: "", component: StagesStatusListComponent },
                  { path: "register", component: StagesStatusFormComponent, canDeactivate: [FormExitGuard] },
                  { path: "edit/:id", component: StagesStatusFormComponent, canDeactivate: [FormExitGuard] },
                ]
              },
              {
                path: "tasks", children: [
                  { path: "", component: FinancialTasksTableComponent },
                  { path: "register", component: FinancialTasksFormComponent, canDeactivate: [FormExitGuard] },
                  { path: "edit/:id", component: FinancialTasksFormComponent, canDeactivate: [FormExitGuard] },
                ]
              },
              {
                path: "documents", children: [
                  { path: "", component: DocumentsListComponent },
                  { path: "register", component: DocumentsFormComponent, canDeactivate: [FormExitGuard] },
                  { path: "edit/:id", component: DocumentsFormComponent, canDeactivate: [FormExitGuard] },
                ]
              },
              {
                path: "examsTemplate", children: [
                  { path: "", component: ExamsTemplateListComponent },
                  { path: "register", component: ExamsTemplateFormComponent, canDeactivate: [FormExitGuard] },
                  { path: "edit/:id", component: ExamsTemplateFormComponent, canDeactivate: [FormExitGuard] },
                ]
              },
              {
                path: "exams", children: [
                  { path: "", component: ExamsRegisterListComponent },
                  { path: "register", component: ExamsRegisterFormComponent, canDeactivate: [FormExitGuard] },
                  { path: "edit/:id", component: ExamsRegisterFormComponent, canDeactivate: [FormExitGuard] },
                ]
              },
              {
                path: "schedulingStatus", children: [
                  { path: "", component: SchedulingStatusListComponent },
                  { path: "register", component: SchedulingStatusFormComponent, canDeactivate: [FormExitGuard] },
                  { path: "edit/:id", component: SchedulingStatusFormComponent, canDeactivate: [FormExitGuard] },
                ]
              },
              {
                path: "attendanceType", children: [
                  { path: "", component: AttendanceTypeListComponent },
                  { path: "register", component: AttendanceTypeFormComponent, canDeactivate: [FormExitGuard] },
                  { path: "edit/:id", component: AttendanceTypeFormComponent, canDeactivate: [FormExitGuard] },
                ]
              },
              {
                path: "triggers", children: [
                  { path: "", component: TriggersListComponent },
                  { path: "register", component: TriggersFormComponent, canDeactivate: [FormExitGuard] },
                  { path: "edit/:id", component: TriggersFormComponent, canDeactivate: [FormExitGuard] },
                ]
              },
              {
                path: "welcomeMessage", children: [
                  { path: "", component: WelcomeMessageFormComponent },
                ]
              },
              {
                path: "manualTrigger", children: [
                  { path: "", component: ManualTriggerFormComponent },
                ]
              },
              {
                path: "endConversation", children: [
                  { path: "", component: EndConversationFormComponent },
                ]
              },
              {
                path: "statusReminder", children: [
                  { path: "", component: StatusReminderListComponent },
                  { path: "register", component: StatusReminderFormComponent, canDeactivate: [FormExitGuard] },
                  { path: "edit/:id", component: StatusReminderFormComponent, canDeactivate: [FormExitGuard] },
                ]
              },
            ]
          },
        ]
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  providers: [
    FormExitGuard,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
